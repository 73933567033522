/*
    * Navbar *
*/

@use "../../variables.scss" as variables;

.initial-avatar {
    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;

    /* Colors */
    background-color: variables.$secondary-argentinian-blue-shade-500;
    color: #fff;

    /* Rounded border */
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    font-family: variables.$font-family-public-sans;
}

.navbar {
    border-bottom: 1px solid variables.$navbar-bottom-color;
    height: 60px;
}

.navbar-nav .dropdown-menu {
    text-align: center;
}

.navbar-dark {
    z-index: 9999;
    background-color: variables.$secondary-color-blue-50;
}

.navbar-dark .navbar-brand {
    max-width: 160px;
}

.navbar-dark .navbar-nav {
    background-color: variables.$secondary-color-blue-50;
}
.navbar-dark .navbar-nav > li > a {
    // font-weight: 500;
    color: variables.$primary-night;
    padding: 0;
}

.navbar-dark .navbar-nav > li > .active {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.navbar-dark .navbar-nav > li > .dropdown-menu {
    width: 100%;
}

.navbar-dark .navbar-nav > li > .dropdown-menu > .dropdown-item {
    font-family: variables.$font-family-public-sans;
    font-family: variables.$font-weight-semibold;
    width: 100%;
    color: variables.$primary-night;
}

.navbar-dark .navbar-nav > li > .dropdown-menu > .dropdown-item:hover,
.navbar-dark .navbar-nav > li > .dropdown-menu > .dropdown-item:active {
    color: variables.$secondary-argentinian-blue-shade-100;
}

.navbar-dark .navbar-nav .nav-item .nav-link {
    color: variables.$primary-color-dark-blue;
    font-size: 1em;
    font-weight: variables.$font-weight-medium;
    font-family: variables.$font-family-public-sans;
}

.navbar-dark .navbar-nav .nav-item .nav-link:hover,
.navbar-dark .navbar-nav .nav-item .nav-link:focus {
    color: variables.$primary-night;
    font-size: 1em;
    font-family: variables.$font-family-public-sans;
    font-weight: variables.$font-weight-normal;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

Link {
    text-decoration: none;
}

Link:hover {
    text-decoration: none;
}
.dropdown-toggle::after {
    color: variables.$primary-night;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
