@use "../../../variables.scss" as variables;

.blue-link {
    color: blue;
}

.node-data {
    font-size: 0.8em;
}

.node-icon {
    width: 8vw;
    max-width: 30px;
}

.card-text-title {
    font-size: 1.5em;
    font-weight: variables.$font-weight-light;
    color: variables.$information;
}

.subtitle-card-text {
    font-size: 0.9em;
    font-weight: variables.$font-weight-normal;
}

.view-details-text {
    font-size: 0.9em;
    font-weight: variables.$font-weight-semibold;
    color: variables.$primary-color-blue;

    &:hover {
        cursor: pointer;
    }
}

.divider-line {
    border-bottom: 1px solid variables.$secondary-night-tint-200;
}

.open-map {
    font-size: 0.9em;
    font-weight: variables.$font-weight-bold;
    color: variables.$primary-color-blue !important;
    &:hover {
        cursor: pointer;
    }
}

.file-detail {
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin: 0.5rem 0;
}

.file-preview {
    display: flex;
    align-items: center;
    padding: 1rem;
}

.file-preview a {
    color: #0066cc;
    text-decoration: none;
}

.file-preview a:hover {
    text-decoration: underline;
}

.file-preview.pdf i {
    color: #dc3545;
}

.file-preview.doc i {
    color: #0066cc;
}

.file-preview.excel i {
    color: #198754;
}
