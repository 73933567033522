@use "../../../../variables.scss" as variables;

.add-container {
    border: 1px solid #cececf;
    margin-top: 20px;
}

.group-container {
    border: 1px solid #cececf;
    margin-top: 20px;
}

.add-another-node {
    font-weight: variables.$font-weight-semibold;
    font-size: 1em;
    font-family: variables.$font-family-public-sans;
    color: variables.$primary-color-blue;
    cursor: pointer;
    text-align: right;
}

.drag-icon {
    cursor: grab;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 8px;
}

.icon-container {
    display: flex;
    align-items: center;
}

.add-close {
    margin-right: 10px; /* Adjust spacing as needed */
}

.drag-over {
    border: 2px dashed #007bff;
    background-color: #e9f5ff;
}

.toggle-section {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;

    .toggle-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
    }

    .toggle-label {
        font-family: "Inter", sans-serif;
        font-weight: 500;
        color: #9d9d9f;
        font-size: 1em;
    }

    .toggle-content {
        padding-top: 25px;
        border-top: 1px solid #e0e0e0;
    }
}

.form-switch .form-check-input {
    width: 2.5em;
    height: 1.5em;
    margin-left: 0;
    cursor: pointer;
}

.badge {
    background-color: #DBEAFE;
    color: #1C8AD1;
}

